'use client'

import { RootContext } from '@/modules/contextService'
import React, {
  useEffect,
  useRef,
  useContext
} from 'react'
import Image from 'next/image'
import Link from 'next/link'
import styles from './MainFooter.module.scss'
import { useTranslations } from 'next-intl'

interface Props {
  // eslint-disable-next-line no-unused-vars
  toggleRightsMessage?: (show: boolean) => void
}

function MainFooter (props: Props) {
  const { rootData } = useContext(RootContext)
  const { user } = rootData
  const footerRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && props?.toggleRightsMessage) {
          // Perform actions when the footer comes into view
          props.toggleRightsMessage(false)
        } else {
          // Perform actions when the footer goes out of view
          if (props.toggleRightsMessage) props.toggleRightsMessage(true)
        }
      })
    }, {
      threshold: 0.1 // Adjust this value based on your requirement
    })

    if (footerRef.current) {
      observer.observe(footerRef.current)
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (footerRef.current) {
        observer.disconnect()
      }
    }
  })

  const t = useTranslations('MainFooter')

  const variables = {
    logoAlt: t('logo-alt'),
    legal: t('legal'),
    privacyPolicy: t('privacy-policy'),
    termsOfService: t('terms-of-service'),
    communityGuidelines: t('community-guidelines'),
    statementOfFaith: t('statement-of-faith'),
    account: t('account'),
    signUp: t('sign-up'),
    logIn: t('log-in'),
    platform: t('platform'),
    home: t('home'),
    about: t('about'),
    explore: t('explore'),
    contentSuggestions: t('content-suggestions'),
    facebook: t('facebook'),
    instagram: t('instagram'),
    x: t('x'),
    rightsMessage: t('rights-message'),
    ministry: t('ministry'),
    volunteers: t('volunteering'),
    podcast: t('podcast'),
    feedback: t('feedback'),
    contact: t('contact')
  }

  return (
    <>
      <div ref={footerRef} className={styles.footer}>
        <div className={styles.innerFooter}>
          <div className={styles.columnOne}>
            <Link className={styles.upperCell} href='/feed'>
              <Image
                className={styles.footerLogo}
                src={'/img/sslm-dark.svg'}
                alt={variables.logoAlt}
                width={140.7084}
                height={55.6354}
              />
            </Link>
          </div>
          <div className={styles.columnTwo}>
            <div className={styles.columnFooter}>
              <div className={styles.column}>
                <h5>{variables.platform}</h5>
                <Link scroll href={'/'}>{variables.home}</Link>
                <Link scroll href={'/about'}>{variables.about}</Link>
                {/* <Link scroll href={'/topics'}>{variables.explore}</Link> */}
                <Link scroll href={'/podcast'}>{variables.podcast}</Link>
                <Link scroll href={'/contact'}>{variables.contact}</Link>
              </div>
              <div className={styles.column}>
                <h5>{variables.legal}</h5>
                <Link scroll href={'/policies/privacy-policy'}>{variables.privacyPolicy}</Link>
                <Link scroll href={'/policies/terms-of-service'}>{variables.termsOfService}</Link>
                <Link scroll href={'/policies/community-guidelines'}>{variables.communityGuidelines}</Link>
                <Link scroll href={'/policies/statement-of-faith'}>{variables.statementOfFaith}</Link>
              </div>
              {/* <div className={styles.column}>
                <h5>{t('partners')}</h5>
                <Link scroll href={'/volunteers'}>{variables.volunteers}</Link>
                <Link scroll href={'/partnership-opportunities'}>{t('partnerships')}</Link>
                <Link scroll href={'/content-suggestions'}>{variables.contentSuggestions}</Link>
              </div> */}
              {!user &&
                <div className={styles.column}>
                  <h5>{variables.account}</h5>
                  <Link scroll href={'/v1/users/signup'}>{variables.signUp}</Link>
                  <Link scroll href={'/v1/users/login'}>{variables.logIn}</Link>
                </div>
              }
              <div className={styles.column}>
                <h5>{t('social')}</h5>
                <Link target='_blank' href={'https://www.facebook.com/steppingstones.intl'}>{variables.facebook}</Link>
                <Link target='_blank' href={'https://www.instagram.com/steppingstones.intl'}>{variables.instagram}</Link>
                <Link target='_blank' href={'https://x.com/ssiministry'}>{variables.x}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.lowerCell}>
          {
            String(variables.rightsMessage || '')
              .replace('YYYY', String(new Date().getFullYear()))
              .replace('NNNN', variables.ministry || '')
          }
        </div>
      </div>
    </>
  )
}

export default MainFooter
